

.swiper-pagination-bullet {
    width: 13px;
    height: 13px;
    background: black !important;
}

.swiper-pagination-bullet-active {
    width: 22px;
    height: 22px;
    background: linear-gradient(57.49deg, #DDE398 -31.8%, #5CDEA4 145.82%) !important;
}
