@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@400;700&family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@400;700&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto&display=swap');

// @font-face {
//   font-family: "PP Mori";
//   src: url("./assets/fonts/PPMori/PPMori.otf");
// }


body {
  margin: 0;
  font-family: "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.desktop-element {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.mobile-element {
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
}

.toast-message {
  display: flex;
  justify-content: center;
  background: #FFFFFF;
  /* Dialog Shadow */

  // box-shadow: 0px 12px 24px -4px rgba(0, 0, 0, 0.35);

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  padding: 8px;

}

.Toastify__toast-container {
  display: flex;
  justify-content: center;
  background: transparent;
  box-shadow: none;
}

.Toastify__toast {
  margin: 0;
  height: 20px;
  background: transparent;
  box-shadow: none;
}

.Toastify__toast-body {
  background-color: #ffffff;
  padding: 15px;
  height: fit-content;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #000000de;
  margin-bottom: 30px;
  // box-shadow: 0px 12px 12px -4px rgba(0, 0, 0, 0.295);
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success.toast-message {
  background-color: transparent !important;
  box-shadow: none !important;
}

.Toastify__toast-theme--light {
  background: none !important;
  color: none !important;
  box-shadow: none !important;
}

.custom-class {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 87px;
  /* identical to box height, or 197% */

  letter-spacing: -0.02em;

  /* Gradient 4 */

  background: -webkit-linear-gradient(90deg, #CFFF92 0%, #77F9BF 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

.gradient-text {
  background: -webkit-linear-gradient(90deg, #CFFF92 0%, #77F9BF 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

// OPEN ============ STYLES FOR TRIMMING WITH THUMBNAILS
// .rc-slider {
//   height: 100% !important;
//   z-index: 100;
//   padding: 0px !important;
// }

// .rc-slider-rail {
//   opacity: 0;
// }

// .rc-slider-track.rc-slider-track-1 {
//   height: -webkit-fill-available;
//   width: max-content;
//   background-color: transparent;
//   z-index: 60;
//   border: 5px solid #7BE1B4 !important;
//   border-left-width: 12px !important;
//   border-right-width: 12px !important;
// }

// .rc-slider-handle.rc-slider-handle-1 {
//   top: 35%;
//   transform: translateX(100%) !important;
//   border: 2.5px solid #2b855c;
//   width: 4px;
//   height: 30px;
//   z-index: 70;
//   background-color: #2b855c;
// }

// .rc-slider-handle.rc-slider-handle-2 {
//   top: 35%;
//   transform: translateX(-200%) !important;
//   border: 2.5px solid #2b855c;
//   width: 4px;
//   height: 30px;
//   z-index: 70;
//   background-color: #2b855c;
// }

// .rc-slider-tooltip {
//   z-index: 80;
// }

// .rc-tooltip>div>div {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 8px;
//   font-size: 11px;
//   box-shadow: none;
//   background-color: #000000;
//   font-weight: 500;
//   vertical-align: center;
//   padding: 6px;
//   height: 12px !important;
// }
// CLOSE ============ STYLES FOR TRIMMING WITH THUMBNAILS

.rc-slider-handle-dragging {
  border: none !important;
  box-shadow: none !important;
}

/* Firefox */
.caption-scroll {
  scrollbar-width: thin;
  scrollbar-color: #D6D9DC;
}

/* Chrome, Edge, and Safari */
.caption-scroll::-webkit-scrollbar {
  width: 10px;
  border-radius: 15px;
}

@media (max-width: 768px) {
  #survey#survey::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }
}

#survey::-webkit-scrollbar-thumb {
  background: #000000;
  border: 0px none #ffffff;
  border-radius: 50px;
}

.caption-scroll::-webkit-scrollbar-track {
  background: #D6D9DC;
  border-radius: 15px;
}

.caption-scroll::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 15px;
  border: 1px solid #D6D9DC;

  #survey#survey::-webkit-scrollbar {
    width: 13px;
    height: 10px;
  }

  #survey::-webkit-scrollbar-thumb {
    background: #000000;
    border: 0px none #ffffff;
    border-radius: 50px;
  }

  #survey::-webkit-scrollbar-thumb:hover {
    background: #000000;
  }

  #survey::-webkit-scrollbar-thumb:active {
    background: #000000;
  }

  #survey::-webkit-scrollbar-track {
    background: #d9d9d9;
    border: 0px none #ffffff;
    border-radius: 100px;
  }

  #survey::-webkit-scrollbar-track:hover {
    background: #d9d9d9;
  }

  #survey::-webkit-scrollbar-track:active {
    background: #d9d9d9;
  }

  #survey::-webkit-scrollbar-corner {
    background: transparent;
  }
}